<template>
  <n-card class="py-2 my-2 pb-10 w-80 h-64 mx-auto shadow-md rounded-3xl hover:shadow-2xl">
    <div class="-grid grid-cols-3 gap-2">
      <div class="inline-flex z-10">
        <n-avatar round :size="32" class="animate-pulse bg-orange-tint" />
        <div class="z-50 px-2 pt-1">
          <div class="w-32 h-5 bg-orange-tint animate-pulse mb-2"></div>
          <div class="w-16 h-2 bg-orange-tint animate-pulse my-1"></div>
        </div>
      </div>
        <div class="my-3 mx-1">
            <div class="w-12 h-2 bg-orange-tint animate-pulse my-2"></div>
            <div class="w-64 h-16 bg-orange-tint animate-pulse mt-3"></div>
        </div>
    </div>
    <div style="transform: translateX(-50%)" class="absolute left-1/2 bottom-5 w-24 h-8 rounded-full bg-orange-tint animate-pulse">
    </div>
  </n-card>
</template>

<script>
import { NCard, NAvatar, } from "naive-ui";

export default {
  components: {
    NCard,
    NAvatar,
  },
};
</script>