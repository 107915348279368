<template>
  <div>
    <div class="p-4">
      <img
        class="my-4 mx-auto block w-64"
        :src="require('@/assets/sent_success.svg')"
      />
      <div class="text-center mx-4 mt-6 mb-12">
        <p class="text-xl font-bold text-orange">tutor requested!</p>
        <p class="max-w-xs mx-auto">
          We're on the lookout for {{ classDesc }} tutors and we’ll ping
          you as soon as it finds any
        </p>
      </div>
      <notice
        :title="`Know any other ${classDesc} students?`"
        subtitle="More enrolled students means more attractive classes to teach for tutors. Speed up the process by inviting your classmates"
        :action="{ type: 'primary', text: 'invite' }"
        @actionClick="openModal()"
      />
    </div>
    <modal :active="shareModal" :key="modalKey">
      <div
        class="
          mx-auto
          flex
          items-center
          justify-center
          p-3
          max-w-min
          rounded-full
          bg-orange-tint
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="64"
          height="64"
          viewBox="0 0 172 172"
          class="h-16 w-16"
          style="fill: #000000"
        >
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <path d="M0,172v-172h172v172z" fill="none"></path>
            <g>
              <path
                d="M88.99992,66.95268l-8.19016,-13.80703l17.33773,-10.27969l65.11812,109.79781l-17.3374,10.27969l-29.69385,-50.06812v-0.00336l-17.29406,-29.15602z"
                fill="#ff8303"
              ></path>
              <path
                d="M72.5625,83.71596v77.26563h-20.15625v-77.26562v-16.76328h20.15625z"
                fill="#ff8303"
              ></path>
              <path
                d="M116.23438,147.54409v13.4375h-43.67187v-77.26562h26.37781l17.29406,29.15602v0.00336z"
                fill="#ffe9d1"
              ></path>
              <path
                d="M8.73438,83.71596v6.71875v36.95313v20.15625v13.4375h43.67188v-77.26562z"
                fill="#ffe9d1"
              ></path>
              <path
                d="M88.99992,66.95268l9.94039,16.76328h-26.37781v-16.76328z"
                fill="#ff8303"
              ></path>
              <path
                d="M52.40625,66.95268v16.76328h-43.67187v-16.76328h9.56078h32.7875z"
                fill="#ff8303"
              ></path>
              <path
                d="M91.03906,13.16909c4.63627,0 8.39844,3.7625 8.39844,8.39844c0,4.6393 -3.76216,8.39844 -8.39844,8.39844c-4.63963,0 -8.39844,-3.75914 -8.39844,-8.39844c0,-4.63594 3.7588,-8.39844 8.39844,-8.39844z"
                fill="#e74c3c"
              ></path>
              <path
                d="M65.24914,11.8119c1.92156,4.25969 -1.24297,8.61008 -3.58445,14.79469c-6.18461,-2.33812 -11.54617,-2.83867 -13.46773,-7.10172c-1.06156,-2.35492 -0.0168,-5.12305 2.34148,-6.18797c2.1332,-0.96078 3.67852,-0.37625 5.70422,1.2732c0.10414,-2.61023 0.69203,-4.15891 2.82187,-5.11969c2.35156,-1.06156 5.12305,-0.01344 6.18461,2.34148z"
                fill="#e74c3c"
              ></path>
              <path
                d="M31.77969,32.3612c1.20266,2.66734 0.01344,5.80836 -2.65391,7.01102c-4.83078,2.18023 -9.7657,-1.41094 -16.77672,-4.06148c2.65055,-7.01437 3.22164,-13.09484 8.05242,-15.27508c2.6707,-1.20266 5.81172,-0.01344 7.01773,2.65727c1.08844,2.41875 0.42664,4.17234 -1.44453,6.47016c2.95961,0.11758 4.7132,0.77937 5.805,3.19812z"
                fill="#e74c3c"
              ></path>
              <path
                d="M52.73547,49.9576c2.40195,5.31789 0.94063,10.70633 -1.65281,16.96148v0.03359h-32.7875v-0.03359c-0.21164,-0.36953 -0.4132,-0.7525 -0.59797,-1.15562c-2.18023,-4.83414 -0.02688,-10.52828 4.81734,-12.71188c4.38062,-1.97531 7.55523,-0.77266 11.72086,2.61695c0.215,-5.36492 1.4143,-8.54289 5.79492,-10.5182c4.83414,-2.18023 10.52492,-0.03023 12.70516,4.80727z"
                fill="#e74c3c"
              ></path>
              <path
                d="M153.1875,63.55971c1.85438,0 3.35938,1.505 3.35938,3.35938c0,1.85773 -1.505,3.35938 -3.35937,3.35938c-1.8574,0 -3.35937,-1.50164 -3.35937,-3.35937c0,-1.85437 1.50198,-3.35937 3.35938,-3.35937z"
                fill="#000000"
              ></path>
              <path
                d="M139.75,36.68471c1.85438,0 3.35938,1.505 3.35938,3.35938c0,1.85773 -1.505,3.35938 -3.35937,3.35938c-1.8574,0 -3.35937,-1.50164 -3.35937,-3.35937c0,-1.85438 1.50198,-3.35937 3.35938,-3.35937z"
                fill="#000000"
              ></path>
              <path
                d="M119.59375,13.16909c1.85437,0 3.35938,1.505 3.35938,3.35938c0,1.85773 -1.505,3.35938 -3.35937,3.35938c-1.8574,0 -3.35937,-1.50164 -3.35937,-3.35937c0,-1.85438 1.50198,-3.35937 3.35938,-3.35937z"
                fill="#000000"
              ></path>
              <path
                d="M69.20313,36.68471c1.85438,0 3.35938,1.505 3.35938,3.35938c0,1.85773 -1.505,3.35938 -3.35937,3.35938c-1.85773,0 -3.35937,-1.50164 -3.35937,-3.35937c0,-1.85438 1.50164,-3.35937 3.35938,-3.35937z"
                fill="#000000"
              ></path>
              <path
                d="M44.7032,145.16901c0.60805,0.60838 0.9843,1.44823 0.9843,2.37508c0,0.92685 -0.37625,1.76703 -0.9843,2.37474c-0.60805,0.60838 -1.44789,0.98463 -2.37508,0.98463c-0.92719,0 -1.76703,-0.37625 -2.37508,-0.98463c-0.60805,-0.60805 -0.9843,-1.44789 -0.9843,-2.37474c0,-1.85471 1.505,-3.35937 3.35938,-3.35937c0.92719,0 1.76703,0.37625 2.37508,0.9843z"
                fill="#000000"
              ></path>
              <path
                d="M148.56702,116.09127c-1.54766,0 -3.08592,-0.24792 -4.57077,-0.73772c-3.47695,-1.14622 -7.10777,-5.37769 -12.2503,-11.70171c-1.63064,-2.00555 -3.17091,-3.89923 -4.3514,-5.12809c-0.86302,-0.89796 -1.15865,-2.19972 -0.76762,-3.38188c0.3907,-1.18284 1.40355,-2.05157 2.63173,-2.25884c1.62258,-0.27312 3.93316,-0.85328 6.37945,-1.46737c4.70984,-1.18284 10.04789,-2.52323 13.76873,-2.52323c1.2433,0 2.28169,0.14345 3.17461,0.43873c3.56295,1.17477 6.48292,3.61838 8.22106,6.88034c1.76535,3.31335 2.10163,7.06409 0.94667,10.56154c-1.83825,5.5732 -7.13598,9.31823 -13.18219,9.31823zM136.01068,98.24862c0.31175,0.3823 0.62854,0.77131 0.94802,1.16436c2.54137,3.1259 7.27238,8.94366 9.1412,9.55977c0.80491,0.26539 1.63501,0.39977 2.46713,0.39977c3.14034,0 5.87353,-1.89066 6.80206,-4.7048c0.57378,-1.73881 0.39775,-3.61905 -0.49618,-5.29706c-0.92114,-1.72873 -2.48291,-3.02848 -4.39742,-3.6597c-0.03225,-0.01075 -0.32384,-0.10011 -1.06895,-0.10011c-2.89041,0 -8.0148,1.28698 -12.13272,2.32066c-0.4253,0.1075 -0.84723,0.21332 -1.26313,0.31712z"
                fill="#000000"
              ></path>
              <path
                d="M129.817,99.55541c-0.16495,0 -0.3309,-0.01209 -0.49685,-0.03662c-1.23188,-0.18443 -2.26052,-1.03469 -2.67305,-2.20946c-0.56471,-1.60813 -1.56177,-3.83741 -2.61762,-6.19737c-3.3278,-7.43967 -5.46873,-12.58691 -4.92552,-16.20831c1.03939,-6.91561 6.92098,-12.12936 13.68072,-12.12936c0.6581,0 1.32359,0.04972 1.978,0.14748c7.46621,1.12069 12.59833,8.31815 11.44102,16.04471c-0.54321,3.6167 -4.11355,7.96642 -9.49863,14.18966c-1.64945,1.90611 -3.2072,3.70606 -4.18209,5.031c-0.63929,0.86739 -1.64811,1.36827 -2.70598,1.36827zM132.78434,69.4927c-3.46217,0 -6.48763,2.75502 -7.03655,6.40834c-0.29159,1.94609 2.77014,8.79048 4.41489,12.46731c0.20694,0.46292 0.41253,0.92114 0.61275,1.37264c0.27916,-0.32351 0.5627,-0.65105 0.84824,-0.98127c2.67003,-3.08626 7.6409,-8.83012 7.93518,-10.78998c0.60872,-4.06249 -1.99043,-7.83272 -5.79324,-8.40381c-0.32485,-0.04838 -0.65541,-0.07323 -0.98127,-0.07323z"
                fill="#000000"
              ></path>
              <path
                d="M145.93091,166.30351c-1.14723,0 -2.26489,-0.58789 -2.89242,-1.64643l-29.69385,-50.06813c-0.01814,-0.03057 -0.03595,-0.06181 -0.05308,-0.09272l-35.37086,-59.63663c-0.45486,-0.76661 -0.58621,-1.68204 -0.3655,-2.5454c0.22071,-0.86336 0.77501,-1.60377 1.54162,-2.05795l17.33773,-10.27969c1.59537,-0.94634 3.65634,-0.41959 4.60268,1.17578l65.11812,109.79815c0.45452,0.76627 0.58588,1.6817 0.3655,2.54506c-0.22071,0.86302 -0.77568,1.60343 -1.54195,2.05795l-17.3374,10.27969c-0.5375,0.31847 -1.12808,0.46998 -1.71059,0.47031zM119.17719,111.25141l27.92682,47.08937l11.55827,-6.85279l-61.69055,-104.01935l-11.55827,6.85279l33.71032,56.83693c0.01814,0.03057 0.03595,0.06148 0.05341,0.09305z"
                fill="#000000"
              ></path>
              <path
                d="M72.5625,164.34096c-1.85538,0 -3.35937,-1.50433 -3.35937,-3.35937v-90.70312h-13.4375v90.70313c0,1.85505 -1.50399,3.35938 -3.35937,3.35938c-1.85538,0 -3.35937,-1.50433 -3.35937,-3.35937v-94.02891c0,-1.85538 1.50399,-3.39297 3.35938,-3.39297h20.15625c1.85538,0 3.35938,1.50399 3.35938,3.35938v94.0625c0,1.85538 -1.50399,3.35938 -3.35937,3.35938z"
                fill="#000000"
              ></path>
              <path
                d="M116.23438,150.90346h-26.875c-1.85538,0 -3.35937,-1.50433 -3.35937,-3.35937c0,-1.85505 1.50399,-3.35937 3.35938,-3.35937h26.875c1.85538,0 3.35938,1.50433 3.35938,3.35938c0,1.85505 -1.50399,3.35938 -3.35937,3.35938z"
                fill="#000000"
              ></path>
              <path
                d="M32.25,150.90346h-23.51562c-1.85538,0 -3.35937,-1.50433 -3.35937,-3.35937c0,-1.85505 1.50399,-3.35937 3.35938,-3.35937h23.51563c1.85538,0 3.35938,1.50433 3.35938,3.35938c0,1.85505 -1.50399,3.35938 -3.35937,3.35938z"
                fill="#000000"
              ></path>
              <path
                d="M116.23438,164.34096h-107.5c-1.85538,0 -3.35937,-1.50433 -3.35937,-3.35937v-94.02891c0,-1.85538 1.50399,-3.35937 3.35938,-3.35937h80.26555c1.85538,0 3.36139,1.50399 3.36139,3.35937c0,1.85538 -1.50265,3.35938 -3.35803,3.35938h-76.90953v87.31016h100.78125v-44.74687c0,-1.85505 1.50399,-3.36105 3.35938,-3.36105c1.85538,0 3.35938,1.50265 3.35938,3.3577v48.10961c0,1.85538 -1.50399,3.35938 -3.35937,3.35938z"
                fill="#000000"
              ></path>
              <path
                d="M52.40625,87.07534h-43.67187c-1.85538,0 -3.35937,-1.50399 -3.35937,-3.35937c0,-1.85538 1.50399,-3.35937 3.35938,-3.35937h43.67188c1.85538,0 3.35938,1.50399 3.35938,3.35938c0,1.85538 -1.50399,3.35938 -3.35937,3.35938z"
                fill="#000000"
              ></path>
              <path
                d="M98.94031,87.07534h-26.37781c-1.85538,0 -3.35937,-1.50399 -3.35937,-3.35937c0,-1.85538 1.50399,-3.35937 3.35938,-3.35937h26.37781c1.85538,0 3.35938,1.50399 3.35938,3.35938c0,1.85538 -1.50399,3.35938 -3.35937,3.35938z"
                fill="#000000"
              ></path>
              <path
                d="M156.54688,36.68471c-0.85966,0 -1.71966,-0.32787 -2.37541,-0.98396l-5.87891,-5.87891c-1.31184,-1.31184 -1.31184,-3.43899 0,-4.75116l3.50349,-3.50349l-3.50349,-3.50349c-1.31184,-1.31184 -1.31184,-3.43899 0,-4.75116l5.87891,-5.87891c1.31184,-1.31184 3.43899,-1.31184 4.75116,0c1.31217,1.31184 1.31184,3.43899 0,4.75116l-3.50349,3.50349l3.50349,3.50349c1.31184,1.31184 1.31184,3.43899 0,4.75116l-3.50349,3.50349l3.50349,3.50349c1.31184,1.31184 1.31184,3.43899 0,4.75116c-0.65609,0.65575 -1.51609,0.98363 -2.37575,0.98363z"
                fill="#000000"
              ></path>
              <path
                d="M126.31519,51.24491c-0.27009,0 -0.54388,-0.03292 -0.81767,-0.10112l-8.95945,-2.2407c-1.1741,-0.29361 -2.09961,-1.19627 -2.42245,-2.36231c-0.32317,-1.16638 0.00605,-2.41606 0.86168,-3.27203l2.28102,-2.28068l-5.04309,-1.00916c-1.8191,-0.36416 -2.99858,-2.13421 -2.63476,-3.95331c0.36416,-1.81944 2.13421,-2.99757 3.95365,-2.63476l11.1968,2.2407c1.21441,0.24322 2.1967,1.13513 2.55581,2.32066c0.35878,1.18586 0.03595,2.47284 -0.83984,3.34896l-2.5239,2.52356l3.20384,0.80121c1.79995,0.45016 2.8941,2.2743 2.44395,4.07391c-0.38129,1.52616 -1.75057,2.54506 -3.25557,2.54506z"
                fill="#000000"
              ></path>
              <path
                d="M61.66435,29.96697c-0.39473,0 -0.79651,-0.07021 -1.18754,-0.21802c-1.43109,-0.54086 -2.8343,-0.98362 -4.19116,-1.41161c-4.67155,-1.47342 -9.08375,-2.86488 -11.15111,-7.4521c-1.82011,-4.0383 -0.01613,-8.80694 4.02151,-10.63007c1.13816,-0.51264 2.22559,-0.76157 3.32713,-0.76157c0.53716,0 1.04913,0.06148 1.53591,0.16931c0.70312,-1.27589 1.83623,-2.43051 3.66407,-3.25523c1.04544,-0.47199 2.15571,-0.71152 3.29924,-0.71152c3.1558,0 6.03277,1.85874 7.32915,4.73504c2.06837,4.58521 0.19216,8.81735 -1.79458,13.29775c-0.57512,1.29773 -1.17007,2.63946 -1.71059,4.06686c-0.50861,1.34375 -1.78584,2.17116 -3.14202,2.17116zM52.48318,16.21268c-0.07223,0 -0.24188,0.02184 -0.56505,0.1673c-0.66079,0.29831 -0.95742,1.08138 -0.65844,1.74452c0.79315,1.75964 3.07752,2.55346 7.04696,3.8055c0.46662,0.14714 0.95406,0.301 1.4573,0.46359c0.20929,-0.48073 0.41455,-0.94398 0.61107,-1.38709c1.68943,-3.8102 2.60654,-6.05158 1.81205,-7.81256c-0.25901,-0.57445 -0.81062,-0.77803 -1.20434,-0.77803c-0.18376,0 -0.36382,0.0393 -0.53582,0.11691c-0.53179,0.23986 -0.774,0.34904 -0.84723,2.19199c-0.05073,1.27152 -0.81566,2.40498 -1.97531,2.92837c-1.15999,0.52305 -2.51584,0.34602 -3.50248,-0.45721c-1.08945,-0.88755 -1.50903,-0.98329 -1.6387,-0.98329z"
                fill="#000000"
              ></path>
              <path
                d="M25.96159,43.40413c-0.00034,0 -0.00067,0 -0.00101,0c-3.38726,-0.00034 -6.67676,-1.45797 -10.15976,-3.0016c-1.4788,-0.65541 -3.00798,-1.33266 -4.63963,-1.94978c-1.73545,-0.65609 -2.61023,-2.59445 -1.95448,-4.3299c0.61712,-1.63333 1.12069,-3.2297 1.6078,-4.77334c1.64038,-5.20065 3.19006,-10.11273 8.20527,-12.37627c1.13043,-0.50895 2.32637,-0.76661 3.55758,-0.76661c3.40137,0 6.50409,2.00286 7.90394,5.10289c0.94802,2.10666 0.98363,3.956 0.55127,5.57052c1.49694,0.74377 2.8595,1.99413 3.8092,4.09844c0.95137,2.10935 1.0236,4.46226 0.20425,6.62637c-0.81902,2.16377 -2.43118,3.87873 -4.53919,4.82944c-1.42538,0.64366 -2.95491,0.96985 -4.54523,0.96985zM16.57247,33.40428c0.68027,0.29327 1.33233,0.58218 1.95045,0.85597c2.93677,1.30142 5.47309,2.42513 7.43833,2.42547h0.00034c0.6487,0 1.21509,-0.11892 1.78248,-0.37524c0.473,-0.21332 0.83447,-0.59797 1.01823,-1.08373c0.18376,-0.48543 0.16797,-1.01285 -0.04502,-1.48451c-0.32821,-0.72697 -0.50928,-1.12808 -2.87596,-1.22214c-1.27152,-0.05039 -2.40565,-0.81532 -2.9287,-1.97531c-0.52339,-1.15999 -0.34635,-2.51617 0.45721,-3.50282c1.49593,-1.83691 1.31486,-2.2397 0.98598,-2.97036c-0.31343,-0.69405 -1.01218,-1.14521 -1.77845,-1.14521c-0.27345,0 -0.54153,0.05812 -0.79617,0.17301c-2.16747,0.97825 -3.09936,3.64425 -4.55901,8.27246c-0.20391,0.645 -0.41891,1.32662 -0.6497,2.03242z"
                fill="#000000"
              ></path>
              <path
                d="M51.08131,70.27947c-0.42866,0 -0.8647,-0.08264 -1.2853,-0.25699c-1.71395,-0.71051 -2.52692,-2.67608 -1.81641,-4.3897c2.408,-5.80802 3.53138,-10.22493 1.69413,-14.29212c-0.68632,-1.52314 -1.92324,-2.68582 -3.48401,-3.27573c-1.56043,-0.58991 -3.25759,-0.53784 -4.77837,0.14815c-2.47552,1.11632 -3.61435,2.4772 -3.81927,7.59051c-0.05106,1.27119 -0.81566,2.4043 -1.97498,2.9277c-1.15932,0.52305 -2.51516,0.34669 -3.50181,-0.45654c-3.9691,-3.2297 -5.74386,-3.27673 -8.21972,-2.16008c-1.52482,0.68733 -2.69019,1.92627 -3.28211,3.48938c-0.59125,1.56144 -0.53918,3.25859 0.14647,4.77905c0.12127,0.26438 0.27144,0.55396 0.45083,0.86773c0.92181,1.61015 0.36382,3.66273 -1.24599,4.58454c-1.61048,0.92215 -3.66273,0.36382 -4.58454,-1.24599c-0.28118,-0.49114 -0.52205,-0.95742 -0.73671,-1.42605c-1.4321,-3.17461 -1.54027,-6.69792 -0.31309,-9.93871c1.22752,-3.24079 3.64391,-5.81037 6.80408,-7.23475c4.14009,-1.8668 7.54213,-1.44621 10.52459,-0.03158c0.91308,-3.17293 2.85009,-6.0022 6.9912,-7.86934c3.15613,-1.42303 6.6781,-1.53255 9.91721,-0.30772c3.24045,1.2255 5.80836,3.63921 7.23139,6.79702c3.13967,6.95088 0.81398,13.7768 -1.61216,19.62849c-0.53683,1.29202 -1.78752,2.07273 -3.10541,2.07273z"
                fill="#000000"
              ></path>
              <path
                d="M91.03906,33.32534c-6.48326,0 -11.75781,-5.27455 -11.75781,-11.75781c0,-6.48326 5.27455,-11.75781 11.75781,-11.75781c6.48326,0 11.75781,5.27455 11.75781,11.75781c0,6.48326 -5.27455,11.75781 -11.75781,11.75781zM91.03906,16.52846c-2.77854,0 -5.03906,2.26052 -5.03906,5.03906c0,2.77854 2.26052,5.03906 5.03906,5.03906c2.77854,0 5.03906,-2.26052 5.03906,-5.03906c0,-2.77854 -2.26052,-5.03906 -5.03906,-5.03906z"
                fill="#000000"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-bold text-gray-900">
          Share & Win rewards with your classmates
        </h3>
        <div class="mt-2 mx-2">
          <p class="text-sm text-gray-500 leading-6">
            Send your own referral link to students taking
            <span
              class="
                bg-orange-tint
                p-1
                rounded-lg
                uppercase
                text-orange
                font-bold
              "
              >{{ classDesc }}</span
            >
            and get 20 EGP each when they take their first session!
          </p>
        </div>
      </div>

      <div class="relative">
        <p class="text-xs font-bold text-center pt-4">invite via email</p>
        <transition name="scale-up" appear>
          <div
            v-if="email != ''"
            class="
              absolute
              right-1
              top-11
              font-bold
              px-4
              py-1
              text-white
              rounded-lg
              cursor-pointer
              transition
            "
            :class="{ 'bg-gray-300': error, 'bg-orange': !error }"
            @click="invite()"
          >
            Invite!
          </div>
        </transition>
        <form-input
          v-model:value="email"
          placeholder="type emails here"
          :label="``"
          required
          email
          @error="error = $event"
          @clean="error = null"
        />
      </div>
      <p class="font-bold uppercase text-center -my-1">or</p>
      <p class="text-xs font-bold text-center pt-4">share on social</p>
      <div class="mt-1 flex justify-between">
        <a
          :href="`https://wa.me/?text=join%20me%20%26%20${classDesc}%20students%20on%20tutoruu%21%0A%0Astudents.tutoruu.com`"
          target="_blank"
          class="w-24 h-20 bg-white rounded-3xl hover:shadow-lg transition"
        >
          <img
            class="w-12 h-12 mx-auto"
            src="https://img.icons8.com/color/480/000000/whatsapp--v1.png"
          />
          <p class="font-bold text-xs text-center">Whatsapp</p>
        </a>
        <a
          :href="`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstudents.tutoruu.com%3Fref%3D${user._id}&amp;src=sdkpreparse`"
          target="_blank"
          class="w-24 h-20 bg-white rounded-3xl hover:shadow-lg transition"
        >
          <img
            class="w-12 h-12 mx-auto"
            src="https://img.icons8.com/fluency/240/000000/facebook.png"
          />
          <p class="font-bold text-xs text-center">Facebook</p>
        </a>
        <a
          :href="`https://twitter.com/intent/tweet?text=join%20me%20%26%20${classDesc}%20students%20on%20tutoruu%21%0A%0Astudents.tutoruu.com`"
          class="w-24 h-20 bg-white rounded-3xl hover:shadow-lg transition"
          target="_blank"
        >
          <img
            class="w-12 h-12 mx-auto"
            src="https://img.icons8.com/color/480/000000/twitter--v1.png"
          />
          <p class="font-bold text-xs text-center">Twitter</p>
        </a>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FormInput from "./formInput.vue";
import Modal from "./modal.vue";
import Notice from "./Notice.vue";
export default {
  computed: {
    ...mapState(["user"]),
  },
  components: { Notice, Modal, FormInput },
  props: {
    classDesc: String,
  },
  methods: {
    ...mapActions(["send_invite"]),
    ...mapMutations(["fireToast"]),
    openModal() {
      this.shareModal = true;
      this.modalKey++;
    },
    invite() {
      if (this.error) {
        this.fireToast({
          text: `error sending invite - invalid email :(`,
          type: "error",
        });
      } else {
        this.send_invite(this.email);
        this.email = "";
      }
    },
  },
  data() {
    return {
      shareModal: false,
      modalKey: 0,
      email: "",
      error: null,
    };
  },
};
</script>
