<template>
  <div class="rounded-3xl bg-orange-tint p-4 m-4">
    <p class="text-lg font-bold capitalize">{{ title }}</p>
    <p class="font-extralight">{{ subtitle }}</p>
    <div class="flex w-full justify-end" v-if="action">
      <Button :type="action.type" small :text="action.text" @click="this.$emit('actionClick')" />
    </div>
  </div>
</template>

<script>
import Button from "./button";
export default {
  name: "notice",
  components: { Button },
  props: {
    title: String,
    subtitle: String,
    action: {
      type: Object,
      default: null,
    },
  },
  emits: ["actionClick"],
};
</script>
