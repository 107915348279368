<template>
<div class="">
  <n-card class="py-2 my-2 pb-10 w-80 h-64 mx-auto shadow-md rounded-3xl hover:shadow-2xl relative" :class="{'h-36':size}">
<div class=" p-5 -mt-16 absolute mx-56  z-30">
  <span class="absolute z-40 my-6 mx-3 font-poppins font-medium leading-5 text-xs text-black ">{{rate}} EGP</span>
    <svg width="70" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.75498 47.375C5.45498 44.63 4.519 41.618 2.77 39.194C-0.796005 34.251 -1.07601 32.947 2.85099 28.458C3.96505 27.2325 4.80586 25.7843 5.31773 24.2092C5.82959 22.6341 6.0008 20.9682 5.81998 19.322C5.37598 13.263 6.15396 12.172 12.149 10.777C13.7665 10.4333 15.2907 9.74456 16.6175 8.75772C17.9444 7.77087 19.0425 6.50924 19.837 5.05899C22.743 0.240987 24.277 -0.30303 29.307 1.79797C30.9273 2.551 32.6907 2.94616 34.4774 2.95651C36.2641 2.96686 38.0321 2.59217 39.661 1.85797C44.792 -0.335032 46.515 0.186968 49.216 4.85797C50.0746 6.42469 51.2669 7.78372 52.7085 8.83899C54.1502 9.89425 55.806 10.62 57.559 10.965C62.975 12.256 64.043 13.755 63.339 19.065C63.0971 20.7718 63.2496 22.5113 63.7848 24.15C64.3199 25.7886 65.2234 27.2829 66.426 28.518C70.185 32.844 70.19 34.546 66.456 38.878C65.2186 40.173 64.2982 41.7372 63.7671 43.4478C63.236 45.1584 63.1086 46.9689 63.395 48.737C64.083 53.464 62.771 55.431 58.007 56.408C56.0424 56.7311 54.1836 57.5196 52.5859 58.7076C50.9883 59.8956 49.698 61.4486 48.823 63.237C46.984 66.742 44.1 67.837 40.479 65.988C38.6624 65.0005 36.6276 64.4833 34.56 64.4833C32.4924 64.4833 30.4576 65.0005 28.641 65.988C25.27 67.662 22.341 67.001 20.553 63.524C19.6187 61.6157 18.2364 59.962 16.524 58.7042C14.8117 57.4464 12.8203 56.6218 10.7199 56.301C6.24495 55.39 5.56298 53.748 6.75498 47.375Z" fill="#FDE7CF"/>
</svg>
</div>
    <div class="-grid grid-cols-3 gap-2">
      <div class="flex flex-wrap w-full mb-2">
        <div class="flex" v-for="course, index in classes" :key="index">
          <tag v-if="priority && index < 1" :text="priority" />
          <tag v-else-if="!priority && index < 1" :text="course.class.desc" />
          <tag v-if="priority && classes[0].class.desc != priority && index == 1 && tagLimit > 1" :text="classes[0].class.desc" @click="router.push(`/results?class=${classes[0].class.desc}`)" />
          <tag v-if="index >= 1 && index < tagLimit && course.class.desc != priority" :text="course.class.desc" @click="router.push(`/results?class=${course.class.desc}`)" />
          <tag v-if="index >= 1 && index === tagLimit && tagLimit < classes.length" :text="`+${classes.length - tagLimit}`" @click="tagLimit+= 2"/>
        </div>
      </div>
      <div class="inline-flex z-10 relative">
      <div class="absolute -mx-3 -my-2">
        <svg width="30" height="30" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.8573 24.1361C9.66732 21.9623 8.57338 20.5328 8.10337 18.9185C8.0844 18.8561 8.06607 18.791 8.04657 18.7182C8.21492 18.5571 8.32442 18.3442 8.35766 18.1136C8.3909 17.8829 8.34594 17.6477 8.22993 17.4456C8.1331 17.2929 7.99384 17.1717 7.82923 17.0969C7.88138 16.286 8.29229 15.6378 9.59789 15.8607C10.6141 16.0357 12.6352 19.1959 12.8936 20.5226C23.5922 9.79114 30.4912 8.65966 30.4912 8.65966C28.9837 8.2929 27.8122 6.62416 26.4741 5.55483C27.2579 4.53068 28.1082 3.5592 29.0196 2.64664C30.1082 1.75128 31.2434 0.914188 32.4205 0.138962C17.9445 -0.339522 10.2289 10.2929 0.663765 17.8077C0.783863 18.064 0.899167 18.3221 1.02139 18.5818C2.96253 17.9731 4.89592 17.3658 6.82144 16.7599C6.92469 16.8439 7.02291 16.9275 7.12287 17.0101C6.98108 17.0414 6.84554 17.0963 6.72187 17.1723C6.47138 17.3128 6.28669 17.5468 6.20812 17.823C6.12955 18.0993 6.16349 18.3953 6.30255 18.6466C6.34629 18.7147 6.39915 18.7766 6.45971 18.8303C6.20699 19.1887 6.07194 19.6168 6.07323 20.0553C6.55574 24.6877 7.25094 29.2981 7.98181 33.9015C8.02192 34.185 8.10647 34.4605 8.23245 34.7176C8.27345 34.8037 8.32414 34.8848 8.38352 34.9594L9.06882 35.3294L9.67142 34.7841L10.0828 35.5707C10.3957 35.4993 10.6963 35.3818 10.9746 35.222L11.8664 34.3451C12.8905 30.9423 12.5278 27.2725 10.8573 24.1361V24.1361Z" fill="#FF8303"/>
</svg>

      </div>
        <n-avatar round :size="34" :src="imgUrl" />
        <div class="px-2 z-20 ">
          <h2  class="font-bold  text-xl truncate w-36 capitalize">{{ tutorName }}</h2>
          
        </div>
        <!-- <n-tag
          type="success"
          round
          class="font-bold float-right"
          style="margin-top: -2px; font-size: 12px"
          >{{ rate }} EGP</n-tag
        > -->
      </div>
      <div class="flex flex-row ml-10 -mt-3">
        <p v-if="(num_reviews > 0)" >
          <b>{{ num_reviews }}</b> sessions
        </p>
        <n-rate size="small" v-if="(rating >= 0)" readonly :default-value="parseInt(rating + 0.49)" />
        </div>
        <br>

      <div class="">
        <div class="w-64 -my-3">
          <h4 v-if="!!bio" class="font-bold pb-2 text-sm lg:text-md pr-4">Bio</h4>
          <p class="font-poppins font-normal text-xs leading-5  pr-4 line-clamp-3">{{ bio }}</p>
        </div>
        <div class="w-28">
          <h4 class="font-bold pb-2 text-sm lg:text-md" v-if="num_students > 0">
            {{ num_students }} students
          </h4>
           
        </div>
      </div>
    </div>
    <div class="flex flex-row absolute left-1/2 bottom-2 items-end justify-center " style="transform: translateX(-50%) !important;">
      <Button type="primary" text="view profile" @click="() => { this.$emit('clickthrough'); router.push(`/tutor/${username}`); }" small />
    </div>

  </n-card>
  </div>
</template>

<script>
import { NCard, NTag, NAvatar, NSpace, NRate } from "naive-ui";
import { useRouter } from "vue-router";
import Button from './button.vue';
import Students from './students.vue';
import Tag from './tag.vue';
import FilterTag from '../components/Feed/FilterTag.vue'

export default {
  data() {
    return {
      tagLimit: 1,
    }
  },
  components: {
    NCard,
    NTag,
    NAvatar,
    NSpace,
    NRate,
    Button,
    Students,
    Tag,
    FilterTag
  },
  props: {
    tutorName: {
      type: String,
      default: "...",
    },
    rate: {
      type: Number,
      default: 0,
    },
    classes: Array,
    num_reviews: {
      type: Number,
      default: 0,
    },
    rating: {
      type: Number,
      default: 5,
    },
    imgUrl: String,
    num_students: Number,
    
    username: String,
    bio: String,
    priority: {
      type: String,
      default: null,
    },
    size: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style>
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
</style>