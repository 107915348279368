<template>
  <page-layout
    v-if="classPreviewed"
    :title="`${classPreviewed.desc} Tutors`"
    linkDefault="/" :backButton="true"
  >
    <div
      v-if="previewed_tutors.length > 0"
      class="lg:flex lg:flex-wrap lg:justify-center lg:items-start my-8"
    >
      <transition-group tag="ul" name="scale-up" appear class="relative">
        <li v-for="(tutor, index) in previewed_tutors" :key="index">
          <tutor-card
            v-if="(tutor && tutor.user)"
          class="mb-6"
            :imgUrl="tutor.user.img_url"
            :classes="tutor.courses"
            :tutorName="tutor.user.name"
            :bio="tutor.user.bio"
            :rate="tutor.rate"
            :rating="tutorCourseMeta(tutor).rating"
            :num_reviews="tutorCourseMeta(tutor).num_sessions"
            :student_imgs="tutorCourseMeta(tutor).student_imgs"
            :username="tutor.user.username"
            :priority="classPreviewed.desc"
          />
        </li>
      </transition-group>
    </div>
    <tutor-requested v-else-if="class_previewed.courses.length === 0 && class_previewed.requested_tutor.includes(current_user._id)" :classDesc="classPreviewed.desc" />
    <div
      v-else-if="class_previewed.courses.length === 0"
      class="text-center my-24 mx-8"
    >
      <p class="text-2xl font-bold">no tutors found :(</p>
      <p class="text-lg font-extralight">
        Just say the word and we'll start looking for one!
      </p>
      <button
        @click="request_tutor()"
        class="
          my-8
          mx-auto
          bg-orange
          rounded-full  
          px-8
          text-lg
          py-3
          text-white
          font-bold
          shadow-lg
          capitalize
        "
      >
        request tutor
      </button>
    </div>
    <div
      v-else
      class="lg:flex lg:flex-wrap lg:justify-center lg:items-start my-8"
    >
      <tutor-card-skeleton v-for="index in 12" :key="index" />
    </div>
  </page-layout>
</template>

<script>
import TutorCard from "../components/TutorCard.vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import PageLayout from "../components/base/pageLayout.vue";
import TutorCardSkeleton from "../components/skeletons/TutorCard.vue";
import TutorRequested from '../components/tutorRequested.vue';
import { readFromLocalStorage } from '../helpers';

export default {
  created() {
    if (this.$route.query.class) this.loadClass();
    else if(!!this.classPreviewed) this.getTutors();
    else this.$router.replace('/login')
  },
  components: { PageLayout, TutorCard, TutorCardSkeleton, TutorRequested },
  name: "Results",
  computed: {
    ...mapState(["previewed_tutors", "class_previewed", "username", "user"]),
    ...mapGetters(["getTutorCourseMeta"]),
    current_user() {
      if (!this.user) this.loadUser()
      return this.user;
    }
  },
  data() {
    return {
      classPreviewed: this.$store.state.class_previewed,
    };
  },
  methods: {
    ...mapActions(["get_class_tutors", "get_class", "request_tutor"]),
    ...mapMutations(["loadUser"]),
    async getTutors() {
      await this.get_class_tutors();
    },
    async loadClass() {
      await this.get_class({ desc: this.$route.query.class });
      this.classPreviewed = this.$store.state.class_previewed;
    },
    tutorCourseMeta(tutor) {
      return this.getTutorCourseMeta(tutor);
    },
  },
  watch: {
    classPreviewed: {
      handler: function () {
        this.getTutors();
      },
      deep: true,
    },
    "$route.query.class": {
      handler: function () {
        if (this.$route.query.class) this.loadClass();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
