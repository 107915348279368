<template>
  <div>
    <n-space align="center" v-if="this.imgs.length > 3">
      <n-avatar
        round
        :src="this.imgs[0] ? this.imgs[0]: filler_img"
        size="small"
        style="border: 2px solid white"
      />
      <n-avatar
        v-for="index in 3"
        :key="index"
        round
        :src="
          this.imgs[index]
            ? this.imgs[index]
            : filler_img
        "
        size="small"
        style="border: 2px solid white; margin-left: -24px"
      />

      <n-avatar
        round
        :size="14"
        src="https://img.icons8.com/ios-glyphs/30/ffffff/plus-math.png"
        style="border: 2px solid white; margin-left: -20px"
        class="bg-orange mt-3"
      />
    </n-space>
    <n-space align="center" v-else-if="this.imgs.length > 0">
      <n-avatar
        round
        :src="
          this.imgs[0] ? this.imgs[0]: filler_img
        "
        size="small"
        style="border: 2px solid white"
      />
      <n-avatar
        v-for="(img, index) in this.imgs.slice(1)"
        :key="index"
        round
        :src="img ? img : filler_img"
        size="small"
        style="border: 2px solid white; margin-left: -24px"
      />
    </n-space>
    <div class="h-6 w-20" v-else></div>
  </div>
</template>

<script>
import { NSpace, NAvatar } from "naive-ui";
import { mapState } from 'vuex';

export default {
  components: {
    NSpace,
    NAvatar,
  },
  computed: {
    ...mapState(['filler_img'])
  },
    props: {
      imgs: Array,
    },
};
</script>